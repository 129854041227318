import React, {FC, useRef} from 'react';
import {useRecoilValue} from 'recoil';

import {all} from '../state/allInclusive/allinclusive';
import allTypes from '../types/allinclusive';

import useFetchAllInclusive from '../hooks/pages/useFetchAllInclusive';

import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UIHeroComponent from '../components/UICommon/UIHeroComponent';
import UITextContentWrapper from '../components/UIWrappers/UITextContentWrapper';
import NewsGridComponent from '../components/AllInclusivePage/News/NewsGridComponent';
import ContactFormSection from '../components/AllInclusivePage/News/ContactFormSection/ContactFormSection';

const AllInclusivePage: FC = () => {
  const {isLoading: isLoadingAllInclusivePage, isError: isErrorAllInclusivePage} = useFetchAllInclusive();
  const allInclusiveData: allTypes = useRecoilValue(all);
  const sectionRef = useRef<HTMLDivElement>(null);

  if (isErrorAllInclusivePage) {
    return (
      <UIErrorIndicator message="Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu" />
    );
  }

  if (isLoadingAllInclusivePage || AllInclusivePage == null) {
    return <UILoadingIndicator />;
  }

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <UIPageContainer>
      <UIHeroComponent
        image={allInclusiveData.hero_image}
        text={allInclusiveData.hero_title}
        description={allInclusiveData.hero_description}
        extraButtonTitle={allInclusiveData.hero_cta_button_title}
        extraButtonUrl="mailto:allinclusive@mih.nu"
        buttonTitle={allInclusiveData.hero_additional_button_title}
        height="h-426px"
        isAllinclusive = {true}
        buttonOnClick={scrollToSection}
        isExtraImage={true}
        extraImage={allInclusiveData.hero_additional_image}
        showOverlay={true}
      />
      <UITextContentWrapper
        title={allInclusiveData.who_we_are_title }
        variant='allInclusive'
        heading={allInclusiveData.who_we_are_heading}
        isDescription={true}
        description={allInclusiveData.who_we_are_description}
      />
      <NewsGridComponent/>
      <div ref={sectionRef} className='py-12'>
        <ContactFormSection
          title={allInclusiveData.contact_title}
          heading={allInclusiveData.contact_heading}
          description={allInclusiveData.contact_description}
        />
      </div>
      <UITextContentWrapper
        title={allInclusiveData.cta_title}
        variant='allInclusive'
        heading={allInclusiveData.cta_heading}
        isDescription={true}
        description = {allInclusiveData.cta_description }
        isButton= {true}
        buttonTitle={allInclusiveData.cta_button_title}
      />
    </UIPageContainer>
  );
};

export default AllInclusivePage;
