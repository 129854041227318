import React, {FC, useEffect, useState} from 'react';

import {enrolledListType} from '../../types/event';
import {formatDate} from '../../utils/formatDate';

import UICardComponent from '../UIWrappers/UICardComponent';
import UIButtonCommon from '../UIButtons/UIButtonCommon';
import UIEnrollmentFormMobileComponent from '../UIForms/UIEnrollmentFormMobileComponent';
import SpeakersListComponent from './SpeakersListComponents';


type EventDetailsSectionMobileComponentTypes = {
  id: number;
  title: string;
  description: string;
  information: string;
  speakers: enrolledListType[];
  date: string;
  enrollmentType: string;
  enrollmentDate: string;
  enrollmentUrl: string;
}

const EventDetailsSectionMobileComponent: FC<EventDetailsSectionMobileComponentTypes> = ({
  id,
  title,
  description,
  information,
  speakers,
  date,
  enrollmentType,
  enrollmentDate,
  enrollmentUrl,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const currentDate = new Date() as unknown as string;
  const formattedDate = formatDate(currentDate);
  const eventDate = formatDate(date);

  useEffect(() => {
    if (eventDate < formattedDate) {
      setIsDisabled(true);
    };
  }, [isDisabled]);

  return (
    <div className='block lg:hidden pt-5'>
      <div className='w-full'>
        <div className='h-fit'>
          <UICardComponent width='w-full' padding='pt-5 pb-6 px-7'>
            {enrollmentType === 'Anmälan på webbplats' ? (
              <div className='flex flex-col w-full'>
                <div className='text-black-dark/40 text-sm mb-5'>
                  Anmälan medlem/gäst med fri entré Dvs fri entre en gång, därefter behövs medlemskap.
                </div>
                <UIEnrollmentFormMobileComponent
                  eventId={id}
                  buttonTitle='ANMÄL'
                  buttonVariant='greenEvent'
                  formExtraStyles='mb-8 border-gray-black/20 border'
                  inputBg='bg-gray-input'
                />
              </div>
            ) : (
              <div className='flex flex-col w-full'>
                <div className='text-black-dark/40 text-sm mb-5'>
                  Anmälan medlem/gäst med fri entré Dvs fri entre en gång, därefter behövs medlemskap.
                </div>
                <UIButtonCommon
                  buttonTitle='ANMÄL'
                  buttonUrl={enrollmentUrl}
                  variant='greenEvent'
                />
              </div>
            )}
          </UICardComponent>
        </div>
      </div>
      <div className='flex flex-col w-full space-y-4 pt-6'>
        <div className='w-full h-full columnLeft'>
          <UICardComponent
            backgroundColor='bg-transparent'
            width='w-full'
            padding=''
          >
            <div className='flex flex-col'>
              <h2 className='text-black-body text-25px font-semibold'>
                {title}
              </h2>
              <span className='text-black-dark/40 text-lg uppercase'>
                Om eventet
              </span>
              <div className='mt-4 text-black-dark/70 text-sm eventContentWrapper'
                dangerouslySetInnerHTML={{__html: description}}
              />
            </div>
          </UICardComponent>
        </div>
        <div className='w-full mt-6 max-h-[620px]'>
          <SpeakersListComponent
            speakers={speakers}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <div className='w-full mt-6'>
        <UICardComponent
          backgroundColor='bg-transparent'
          width='w-full'
          padding=''
        >
          <h2 className='text-black-body text-25px font-semibold'>
            Viktig information
          </h2>
          <div className='mt-4 text-black-dark/70 text-sm eventContentWrapper'
            dangerouslySetInnerHTML={{__html: information}}
          />
        </UICardComponent>
      </div>
    </div>
  );
};

export default EventDetailsSectionMobileComponent;
