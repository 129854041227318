import React, {FC} from 'react';

import useFetchSubscriptions from '../hooks/subscriptions/useFetchSubscriptions';
import useFetchCurrentUser from '../hooks/auth/useFetchCurrentUser';

import BecomeMemberSectionComponent from '../components/BecomeMemeberPage/BecomeMemberSectionComponent';
import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UITextWrapper from '../components/UIWrappers/UITextWrapper';
import UICtaComponent from '../components/UICommon/UICtaComponent';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';

const UpgradeSelectPlanPage: FC = () => {
  const {isLoading: isLoadingPage, isError: isErrorPage} = useFetchSubscriptions();
  const {
    isLoading: isLoadingCurrentUser,
  } = useFetchCurrentUser();

  if (isErrorPage) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'/>
    );
  }

  if (isLoadingPage || isLoadingCurrentUser) {
    return <UILoadingIndicator/>;
  }

  return (
    <UIPageContainer variant='wide'>
      <UITextWrapper
        heading='MEDLEMSKAP'
        title='medlemskap som passar just er, FÖR företag av alla storlekar.'
        description='Nu ska vi bara hitta ett medlemskap som passar
        just er. Vi har medlemskap som passar företag av alla storlekar.'
      />
      <BecomeMemberSectionComponent
        isUpgrading={true}
      />
      <UICtaComponent
        title='Vill du bli partner?'
        description='Nam euismod finibus blandit.
        Curabitur augue odio, pretium eu vulputate eu, venenatis a neque.
        Duis id maximus leo, ac molestie tortor. Vestibulum dapibus lacus in aliquam lobortis.'
        buttonTitle='Bli partner'
        buttonUrl='mailto:canita@mih.nu?subject=Förfrågan bli partner MiH'
        variant='white'
        contactInfo='canita@mih.nu'
        extraInfo={`<p>15 000 – 100 000 kr</br>
          För mer information kontakta</p>`}
      />
      <div className='hidden lg:block'>
        <UITextWrapper
          description='Ansökan om medlemskap är bindande. Var noga med att fylla i rätt uppgifter.
          Vi tar ut en avgift på 200 kr ex moms vid omfakturering.
          Medlemskap gäller för innevarande månad och 11 månader framåt.
          Därefter förlängs medlemskapet om du inte meddelar uppsägning.
          Uppsägning ska ske skriftligen till canita@mih.nu senast 1 månad innan medlemskapet löper ut.
          OBS! Priserna är exklusive moms och våra fakturor skickas med epost.
           Önskas e-fakturering kontakta ekonomi@mih.nu'
          padding='py-8'
          descriptionStyles='lg:!w-3/4 uppercase text-black-body text-sm font-normal'
        />
      </div>
    </UIPageContainer>
  );
};

export default UpgradeSelectPlanPage;
