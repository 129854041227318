import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {subscriptionsTypes} from '../../types/subscriptions';
import {subscriptionsArray} from '../../state/subscriptions/subscriptions';

import PlanDetailsBoxComponent from './PlanDetailsBoxComponent';

type BecomeMemberSectionComponentProps = {
  isUpgrading: boolean;
}

const BecomeMemberSectionComponent: FC<BecomeMemberSectionComponentProps> = ({isUpgrading}) => {
  const subscriptionsData: subscriptionsTypes[] = useRecoilValue(subscriptionsArray);

  return (
    <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
      {subscriptionsData && subscriptionsData.map((el:subscriptionsTypes, index: number) => (
        <PlanDetailsBoxComponent
          key={index}
          planTitle={el.title}
          planPrice={`Fr. ${el.items[0]?.price}kr/år`}
          planDescription={el.description}
          planPerks={el.perks}
          planItems={el.items}
          planType={el.type}
          isUpgrading={isUpgrading}
        />
      ))}
    </div>
  );
};

export default BecomeMemberSectionComponent;
