import React, {FC, useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router';

import {selectedPlan} from '../../state/subscriptions/subscriptions';

import RegisterUpgradeCompanyForm from './RegisterUpgradeCompanyForm';
import RegisterUpgradeUserForm from './RegisterUpgradeUserForm';
import RegisterUpgradePublicForm from './RegisterUpgradePublicForm';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';

const RegisterUpgradeSection: FC = () => {
  const navigate = useNavigate();
  const selectedUserPlan = useRecoilValue(selectedPlan);

  useEffect(() => {
    if (selectedUserPlan === undefined) {
      navigate('/become-member/upgrade/');
    }
  }, [selectedUserPlan]);

  if (selectedUserPlan === undefined) {
    return <UILoadingIndicator/>;
  }

  return (
    <div className='flex flex-col space-y-12 -mt-2'>
      {selectedUserPlan.type === 'company' ? (
        <RegisterUpgradeCompanyForm/>
      ) : selectedUserPlan.type === 'personal' ? (
        <RegisterUpgradeUserForm/>
      ) : (
        <RegisterUpgradePublicForm/>
      )}
    </div>
  );
};
export default RegisterUpgradeSection;
