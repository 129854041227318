import React, {FC, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

import {enrolledListType} from '../../types/event';
import {formatDate} from '../../utils/formatDate';
import {useRecoilValue} from 'recoil';
import {isLoggedIn} from '../../utils/isLoggedIn';
import {currentUser} from '../../state/user/currentUser';
import useRefetchCurrentUser from '../../hooks/auth/useRefetchCurrentUser';

import UICardComponent from '../UIWrappers/UICardComponent';
import UIEnrollmentFormComponent from '../UIForms/UIEnrollmentFormComponent';
import SpeakersListComponent from './SpeakersListComponents';
import UIButtonCommon from '../UIButtons/UIButtonCommon';


type EventDetailsSectionComponentTypes = {
  id: number;
  title: string;
  description: string;
  information: string;
  date: string;
  speakers: enrolledListType[];
  enrollmentType: string;
  enrollmentDate: string;
  enrollmentUrl?: string;
}

const EventDetailsSectionComponent: FC<EventDetailsSectionComponentTypes> = ({
  id,
  title,
  description,
  information,
  date,
  speakers,
  enrollmentType,
  enrollmentDate,
  enrollmentUrl,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  // const [displayInfo, setDisplayInfo] = useState(false);
  const currentDate = new Date() as unknown as string;
  const formattedDate = formatDate(currentDate);
  const eventDate = formatDate(date);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isAvaliable, setIsAvaliable] = useState(false);
  const userData = useRecoilValue(currentUser);
  const {refetch} = useRefetchCurrentUser();
  const [columnLeftHeight, setColumnLeftHeight] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const columnLeftRef = useRef(null);
  const infoBoxRef = useRef(null);


  useEffect(() => {
    if (new Date(enrollmentDate) > new Date()) {
      setIsAvaliable(true);
    }
  }, [enrollmentDate]);

  useEffect(() => {
    if (userData != null && speakers && speakers?.filter((el: any) => el.id === userData.id).length > 0) {
      setIsEnrolled(true);
    }
  }, [userData, speakers]);

  useEffect(() => {
    if (isLoggedIn()) {
      refetch();
    }
  }, []);


  useEffect(() => {
    if (new Date(eventDate) < new Date(formattedDate)) {
      setIsDisabled(true);
    };
  }, [isDisabled]);

  useEffect(() => {
    const eventStartDate = new Date(date);
    eventStartDate.setHours(12, 0, 0, 0);

    const twoDaysBeforeEvent = new Date(eventStartDate);
    twoDaysBeforeEvent.setDate(eventStartDate.getDate() - 2);
  }, [date]);

  // eslint-disable-next-line no-unused-vars
  const updateColumnHeights = () => {
    const columnLeft: any = columnLeftRef;
    const infoBox: any = infoBoxRef;

    if (columnLeft && infoBox) {
      const height = columnLeft.current.clientHeight;
      const infoBoxHeight = infoBox.current.clientHeight;
      const minHeight = 600 + infoBoxHeight;
      if (height < minHeight) {
        setColumnLeftHeight(minHeight);
      } else {
        setColumnLeftHeight(height);
      }
    }
  };

  useEffect(() => {
    setLoaded(true);
    window.addEventListener('resize', updateColumnHeights);

    return () => {
      window.removeEventListener('resize', updateColumnHeights);
    };
  }, []);

  useEffect(() => {
    if (loaded) {
      updateColumnHeights();
    }
  }, [loaded, infoBoxRef]);


  return (
    <>
      <div className='hidden lg:block'>
        <div className='flex w-full space-x-6 pt-12'>
          <div className='w-1/2 h-auto columnLeft flex-1' ref={columnLeftRef}>
            <UICardComponent
              width='w-full'
              padding='py-10 pl-6 pr-7'
            >
              <div className='flex flex-col'>
                <h2 className='text-black-body lg:text-33px font-semibold'>
                  {title}
                </h2>
                <span className='text-black-dark/40 lg:text-22px'>
                  Om eventet
                </span>
                <div className='mt-4 text-black-dark/70 text-base eventContentWrapper'
                  dangerouslySetInnerHTML={{__html: description}}
                />
              </div>
            </UICardComponent>
          </div>
          <div className='w-1/2 flex flex-col columnRight lg:rounded-15px lg:overflow-y-hidden flex-1'
            style={{height: columnLeftHeight}}>
            <div className='h-fit infoBox' ref={infoBoxRef}>
              <UICardComponent width='w-full' padding='pt-5 pb-6 px-7' extraStyles='relative'>
                {isAvaliable ? (
                  <>
                    {enrollmentType === 'Anmälan på webbplats' ? (
                      <div className='flex flex-col w-full'>
                        <div className='text-black-dark/40 text-lg mb-5'>
                              Anmälan medlem/gäst med fri entré Dvs fri entre en gång, därefter behövs medlemskap.
                        </div>
                        <UIEnrollmentFormComponent
                          eventId={id}
                          buttonTitle='ANMÄL'
                          buttonVariant='greenEvent'
                          position='top-2 right-2'
                          isEnrolled={isEnrolled}
                          setIsEnrolled={setIsEnrolled}
                          userEmail={userData ? userData.email : ''}
                        />
                        {!isLoggedIn() && (
                          <div className='text-black-dark/40 mt-6'>
                            Är du partner och inte har ett konto? &nbsp;
                            <Link className='text-green-primary underline' to={`/register-partner/${id}`}>
                              Klicka här
                            </Link>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='flex flex-col w-full'>
                        <div className='text-black-dark/40 text-lg mb-5'>
                          Anmälan medlem/gäst med fri entré Dvs fri entre en gång, därefter behövs medlemskap.
                        </div>
                        <UIButtonCommon
                          buttonTitle='ANMÄL'
                          buttonUrl={enrollmentUrl}
                          variant='greenEvent'
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className={`absolute w-full h-full top-0 left-0 flex items-center
                     justify-center backdrop-blur-sm`}>
                    <span className='uppercase font-bold text-base text-red-500'>
                      Det går inte längre att anmäla sig till detta evenemang
                    </span>
                  </div>
                )}
                <>
                  {!isAvaliable && isDisabled && (
                    <div className={`absolute w-full h-full top-0 left-0 flex items-center
                     justify-center backdrop-blur-sm`}>
                      <span className='uppercase font-bold text-base text-green-primary'>
                        Registrering för detta evenemang har inaktiverats
                      </span>
                    </div>
                  )}
                </>
              </UICardComponent>
            </div>
            <div className='h-full mt-6 max-h-[620px] lg:max-h-full'>
              <SpeakersListComponent
                speakers={speakers}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </div>
        <div className='w-full mt-6'>
          <UICardComponent
            width='w-full'
            padding='py-10 pl-6 pr-7'
          >
            <h2 className='text-black-body lg:text-33px font-semibold'>
                Viktig information
            </h2>
            <div className='mt-4 text-black-dark/70 text-base eventContentWrapper'
              dangerouslySetInnerHTML={{__html: information}}
            />
          </UICardComponent>
        </div>
      </div>
    </>
  );
};

export default EventDetailsSectionComponent;
