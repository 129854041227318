import React, {FC, useEffect, useRef, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router';

import actions from '../../api/user/update';
import {selectedPlan} from '../../state/subscriptions/subscriptions';
import {currentUser} from '../../state/user/currentUser';
import {isValidEmail, isValidFullName} from '../../utils/isValid';
import {RegisterCompanyFormErrorMessagesType, RegisterUserFormErrorMessagesType} from '../../types/register';
import {EventTargetType} from '../../types/eventTarget';
import useMutateCreateSubscription from '../../hooks/subscriptions/useMutateCreateSubscription';

import RegisterUpgradePageFormComponent from './RegisterUpgradePageFormComponent';
import PlanUpgradeSummaryComponent from './PlanUpgradeSummaryComponent';
import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';

const RegisterUpgradeCompanyForm: FC = () => {
  const navigate = useNavigate();
  const plan = useRecoilValue(selectedPlan);
  const userData = useRecoilValue(currentUser);
  const [isCompanyValid, setIsCompanyValid] = useState(false);
  const [isUserValid, setIsUserValid] = useState(false);
  const [triggerScroll, setTriggereScroll] = useState(false);
  const [formValues, setformValues] = useState({
    avatar: userData.avatar,
    email: userData.email,
    full_name: userData.full_name,
    work_place: userData.work_place,
    reference: userData.reference,
    invoice_email: userData.invoice_email,
    organisation: {
      avatar: '',
      company_name: '',
      invoice_email: '',
      org_no: undefined,
      street: '',
      city: '',
      postal_code: '',
      is_organisation: true,
    },
  });
  const [userFormErrorMessages, setUserFormErrorMessages] = useState<
    Partial<RegisterUserFormErrorMessagesType>
  >({
    avatar: '',
    email: '',
    full_name: '',
    work_place: '',
    reference: '',
  });
  const [companyFormErrorMessages, setCompanyFormErrorMessages] =
    useState<RegisterCompanyFormErrorMessagesType>({
      avatar: '',
      company_name: '',
      invoice_email: '',
      org_no: '',
      street: '',
      city: '',
      postal_code: '',
    });
  const summaryRef = useRef<HTMLDivElement>(null);
  const userInfoRef = useRef<HTMLDivElement>(null);
  const companyInfoRef = useRef<HTMLDivElement>(null);
  const {isLoading, isError, error, isSuccess, mutate} =
    useMutateCreateSubscription();

  const onCompanyInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setformValues((prevValues) => ({
      ...prevValues,
      organisation: {
        ...prevValues.organisation,
        [name]: value,
      },
    }));
    setCompanyFormErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: '',
    }));
  };

  const onUserInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setformValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setUserFormErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: '',
    }));
  };

  const setUserAvatar = (file: any) => {
    setformValues((prevValues) => ({
      ...prevValues,
      avatar: file,
    }));
    setUserFormErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      avatar: '',
    }));
  };

  const setOrganisationAvatar = (file: any) => {
    setformValues((prevValues) => ({
      ...prevValues,
      organisation: {
        ...prevValues.organisation,
        avatar: file,
      },
    }));
    setCompanyFormErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      avatar: '',
    }));
  };

  const validateFields = (
      obj: any,
      setFormErrorMessages: Function,
      defaultErrorMessages: any,
      setIsValid: Function,
  ) => {
    let isValid = true;
    const tmpErrorMessages: any = {...defaultErrorMessages};
    let isEmailValid: boolean = true;
    let isFullNameValid: boolean = true;
    let isInvoiceEmailValid: boolean = true;
    let isNumber: boolean = true;
    if (obj.email) {
      isEmailValid = isValidEmail(obj.email);
    }
    if (obj.invoice_email) {
      isInvoiceEmailValid = isValidEmail(obj.invoice_email);
    }
    if (obj.full_name) {
      isFullNameValid = isValidFullName(obj.full_name);
    }
    if (obj.full_name) {
      isFullNameValid = isValidFullName(obj.full_name);
    }
    if (obj.org_no) {
      isNumber = isNaN(obj.org_no) ? false : true;
    }

    for (const key in obj) {
      if (key !== 'reference' && (obj[key] === null || obj[key] === undefined || obj[key] === '')) {
        tmpErrorMessages[key] = `Det här fältet får inte vara tomt.`;
        isValid = false;
      }
    }

    if (!isEmailValid) {
      tmpErrorMessages.email = `Det angivna värdet är inte en giltig e-postadress.`;
      isValid = false;
    }

    if (!isFullNameValid) {
      tmpErrorMessages.full_name = `Fältet ska innehålla namn och efternamn.`;
      isValid = false;
    }

    if (!isInvoiceEmailValid) {
      tmpErrorMessages.invoice_email = `Det angivna värdet är inte en giltig e-postadress.`;
      isValid = false;
    }

    if (!isNumber) {
      tmpErrorMessages.org_no = `Organisationsnummer måste vara ett nummer.`;
      isValid = false;
    }

    setTriggereScroll(!triggerScroll);
    setFormErrorMessages(tmpErrorMessages);
    setIsValid(isValid);
  };

  useEffect(() => {
    if (userInfoRef.current && isCompanyValid) {
      window.scrollTo({
        top: userInfoRef.current.offsetTop - 148,
        behavior: 'smooth',
      });
    }
  }, [isCompanyValid, triggerScroll]);

  useEffect(() => {
    if (summaryRef.current && isUserValid) {
      window.scrollTo({
        top: summaryRef.current.offsetTop - 148,
        behavior: 'smooth',
      });
    }
  }, [isUserValid, triggerScroll]);

  const handleSubmit = () => {
    actions
        .updateUserCreateOrganisation(formValues)
        .then((res: any) => {
          mutate({
            type: 'organisation',
            object_id: userData.organisation.id,
            subscription: plan.selectedId,
          });
        })
        .catch((error) => {
          const errors: any = error.response.data;
          if (errors.email) {
            setUserFormErrorMessages((prevErrorMessages) => ({
              ...prevErrorMessages,
              email: 'Användare med denna e-postadress finns redan.',
            }));
          }
          if (companyInfoRef.current) {
            window.scrollTo({
              top: companyInfoRef.current.offsetTop - 148,
              behavior: 'smooth',
            });
          }
        });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/member-portal/');
    }

    if (isError) {
      console.log(error);
    }
  }, [isSuccess, isError, error]);

  const companyFormFields = [
    {
      id: 'org_no_company',
      type: 'text',
      label: 'Organisationsnummer',
      name: 'org_no',
      value: formValues.organisation.org_no,
    },
    {
      id: 'company_name_company',
      type: 'text',
      label: 'Företagsnamn',
      name: 'company_name',
      value: formValues.organisation.company_name,
    },
    {
      id: 'invoice_email_company',
      type: 'text',
      label: 'Faktureringsmail',
      name: 'invoice_email',
      value: formValues.organisation.invoice_email,
    },
    {
      id: 'reference_company',
      type: 'text',
      label: 'Referens (valfritt)',
      name: 'reference',
    },
    {
      id: 'street_company',
      type: 'text',
      label: 'Faktureringsaddress',
      name: 'street',
      value: formValues.organisation.street,
    },
    {
      id: 'city_company',
      type: 'text',
      label: 'Stad',
      name: 'city',
      value: formValues.organisation.city,
    },
    {
      id: 'postal_code_company',
      type: 'text',
      label: 'Postnummer',
      name: 'postal_code',
      value: formValues.organisation.postal_code,
    },
  ];

  const userFormFields = [
    {
      id: 'postal_code_user',
      type: 'text',
      label: 'För- och efternamn',
      name: 'full_name',
      value: formValues.full_name,
    },
    {
      id: 'work_place_user',
      type: 'text',
      label: 'Arbetsplats',
      name: 'work_place',
      value: formValues.work_place,
    },
    {
      id: 'invoice_email_user',
      type: 'text',
      label: 'Faktureringsmail',
      name: 'invoice_email',
      value: formValues.invoice_email,
    },
    {
      id: 'reference_user',
      type: 'text',
      label: 'Referens (valfritt)',
      name: 'reference',
      value: formValues.reference,
    },
    {
      id: 'email_user',
      type: 'text',
      label: 'Email',
      name: 'email',
      value: formValues.email,
    },
  ];

  if (isLoading) {
    return <UILoadingIndicatorFixed />;
  }

  return (
    <div className="flex flex-col space-y-12 -mt-2">
      <div ref={companyInfoRef}>
        <RegisterUpgradePageFormComponent
          stepName="Företagsuppgifter"
          stepOrder={1}
          totalSteps={3}
          formInputsArray={companyFormFields}
          onChange={onCompanyInputChange}
          formErrorMessages={companyFormErrorMessages}
          keyPrefix="cmp-form-"
          setFile={setOrganisationAvatar}
          isDisabled={false}
        />
      </div>
      <div
        className={`flex items-center text-center px-8 py-2 my-4 bg-green-primary 
        text-white text-lg font-semibold w-fit rounded-full cursor-pointer`}
        onClick={() => {
          validateFields(
              formValues.organisation,
              setCompanyFormErrorMessages,
              companyFormErrorMessages,
              setIsCompanyValid,
          );
        }}
      >
        Nästa
      </div>
      <div ref={userInfoRef}>
        <RegisterUpgradePageFormComponent
          stepName="Personliga uppgifter"
          stepOrder={2}
          totalSteps={3}
          formInputsArray={userFormFields}
          onChange={onUserInputChange}
          formErrorMessages={userFormErrorMessages}
          keyPrefix="user-form-"
          setFile={setUserAvatar}
          isDisabled={!isCompanyValid}
        />
      </div>
      <div
        className={`flex items-center text-center px-8 py-2 my-4 bg-green-primary 
        text-white text-lg font-semibold w-fit rounded-full cursor-pointer ${
    !isCompanyValid && 'opacity-30'
    }`}
        onClick={() => {
          if (!isCompanyValid) return;
          validateFields(
              formValues,
              setUserFormErrorMessages,
              userFormErrorMessages,
              setIsUserValid,
          );
        }}
      >
        Nästa
      </div>
      <div ref={summaryRef}>
        <PlanUpgradeSummaryComponent
          stepName="Slutför köp"
          stepOrder={3}
          totalSteps={3}
          selectedPlan={plan.title}
          selectedPlanPrice={plan.price}
          onSubmit={handleSubmit}
          isDisabled={!isCompanyValid || !isUserValid}
        />
      </div>
    </div>
  );
};

export default RegisterUpgradeCompanyForm;
