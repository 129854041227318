import client from '../clients/base';
import {createFormData} from '../../utils/createFormData';

const UPDATE_USER_URL = 'update-user/';
const UPDATE_ORG_URL = 'update-organisation/';
const UPDATE_USER_CREATE_ORG_URL = 'update-user/organisation/';

const updateUser = async (data: any) => {
  try {
    const res = await client.post(UPDATE_USER_URL, createFormData(data));
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateOrganisation = async (data: any) => {
  try {
    const res = await client.post(UPDATE_ORG_URL, createFormData(data));
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateUserCreateOrganisation = async (data: any) => {
  try {
    const res = await client.post(UPDATE_USER_CREATE_ORG_URL, createFormData(data));
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const actions = {
  updateUser,
  updateOrganisation,
  updateUserCreateOrganisation,
};

export default actions;
