import React, {FC, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {BiUser} from 'react-icons/bi';

import {validateForm} from '../../utils/validateForm';
import {EventTargetType} from '../../types/eventTarget';
import {currentUserType} from '../../types/currentUser';
import {currentUser} from '../../state/user/currentUser';
import useMutateUpdateUser from '../../hooks/user/useMutateUpdateUser';

import ButtonIconComponent from './ButtonIconComponent';
import ProfileInfoBarComponent from './ProfileInfoBarComponent';
import ProfileDetailsForm from './ProfileDetailsForm';
import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';

type RegularUserBoxFormComponentType = {
  refetchCurrentUser: Function;
}

const RegularUserBoxFormComponent: FC<RegularUserBoxFormComponentType> = ({refetchCurrentUser}) => {
  const currentUserData: currentUserType = useRecoilValue(currentUser);
  const [successMessage, setSuccsessMessage] = useState(false);
  const [isUserAvatarChanged, setIsUserAvatarChanged] = useState(false);
  const [formValues, setFormValues] = useState<any>({
    avatar: currentUserData.avatar,
    full_name: currentUserData.full_name,
    work_place: currentUserData.work_place,
    reference: currentUserData.reference,
    invoice_email: currentUserData.invoice_email,
    street: currentUserData.street,
    city: currentUserData.city,
    postal_code: currentUserData.postal_code,
    password: '',
  });
  const [userFormErrorMessages, setUserFormErrorMessages] = useState<any>({
    invoice_email: '',
    password: '',
  });
  const {
    isLoading: isUpdateUserLoading,
    isError: isUpdateUserError,
    isSuccess: isUpdateUserSuccess,
    mutate: mutateUpdateUser,
  } = useMutateUpdateUser();

  const onInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    if (event.target) {
      const {name, value} = event.target;
      setFormValues((prevValues: any) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const setUserAvatar = (file: any) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      avatar: file,
    }));
    setIsUserAvatarChanged(true);
  };

  const handleSubmitUser = () => {
    const {isFormValid, formErrorMessages, updatedFormValues} = validateForm(formValues, true);
    if (!isFormValid) {
      setUserFormErrorMessages(formErrorMessages);
      return;
    }
    const tmpAvatar: any = formValues.avatar;
    if (tmpAvatar instanceof File) {
      updatedFormValues.avatar = formValues.avatar;
    }

    if (!isUserAvatarChanged) {
      delete updatedFormValues.avatar;
    }
    mutateUpdateUser(updatedFormValues);
  };

  useEffect(() => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      avatar: currentUserData.avatar,
    }));
    setIsUserAvatarChanged(false);
  }, [currentUserData]);

  useEffect(() => {
    if (isUpdateUserSuccess) {
      setSuccsessMessage(true);
      setTimeout(() => {
        setSuccsessMessage(false);
      }, 5000);
      setUserFormErrorMessages({});
      refetchCurrentUser();
    }
    if (isUpdateUserError) {
      console.log(isUpdateUserError);
    }
  }, [isUpdateUserSuccess, isUpdateUserError]);

  if (isUpdateUserLoading) {
    return (
      <UILoadingIndicatorFixed />
    );
  }

  return (
    <div className='flex flex-col pt-24 md:pt-32'>
      <div className={`flex w-full justify-between md:justify-start md:space-x-3 md:px-4 
      fixed md:static bg-blue-dark z-20 top-20`}>
        <div className='w-full md:w-fit'>
          <ButtonIconComponent
            variant='white'
            icon={<BiUser/>}
            text='Din profil'
            extraStyles={`md:!text-green-primary md:!bg-green-light border-green-primary`}
          />
        </div>
      </div>
      <div className='rounded-15px mt-16 md:mt-3 px-4'>
        <div className='userForm'>
          <ProfileInfoBarComponent
            userName={formValues.full_name}
            userOrg={formValues.work_place}
            userEmail={currentUserData?.email}
            userIdNum={currentUserData?.organisation?.org_no}
            userId={currentUserData?.id}
            userOrgId={currentUserData?.organisation?.id}
            setFile={setUserAvatar}
            fortnoxId={currentUserData?.fortnox_id}
            avaliableUpgrades={currentUserData.avaliable_upgrades}
            subscriptionEnrollment={currentUserData.subscription_enrollment}
            memberType={currentUserData.member_type}
            isOrganisation={false}
            userAvatar={formValues.avatar}
          />
          <ProfileDetailsForm
            userWorkplace={formValues.work_place}
            userCity={formValues.city}
            userPostalCode={formValues.postal_code}
            userBillingAdress={formValues.street}
            userBillingMail={formValues.invoice_email}
            userReferences={formValues.reference}
            userPassword={formValues.password}
            errorMessages={userFormErrorMessages}
            successMessage={successMessage}
            onChange={onInputChange}
            onClick={handleSubmitUser}
          />
        </div>
      </div>
    </div>
  );
};

export default RegularUserBoxFormComponent;
