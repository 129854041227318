import React, {FC} from 'react';
import UICardComponent from '../UIWrappers/UICardComponent';
import ProfileFormInputComponent from './ProfileFormInputComponent';
import UIButtonCommon from '../UIButtons/UIButtonCommon';
import ProfileFormPasswordInputComponent from './ProfileFormPasswordInputComponent';

type ProfileDetailsBox = {
  userWorkplace: string;
  userCity: string;
  userPostalCode: string;
  onChange: Function;
  onClick: Function;
  successMessage: boolean;
  userBillingMail: string;
  userReferences: string;
  userPassword: string;
  userBillingAdress?: string;
  errorMessages?: any;
}


const ProfileDetailsBox: FC<ProfileDetailsBox> = ({
  userWorkplace,
  userCity,
  userPostalCode,
  onChange,
  onClick,
  successMessage,
  userBillingMail,
  userReferences,
  userPassword,
  userBillingAdress,
  errorMessages,
}) => {
  const profileFormInputs = [
    {
      id: 'workplace',
      name: 'work_place',
      type: 'text',
      label: 'Arbetsplats',
      placeholder: userWorkplace,
      value: userWorkplace,
    },
    {
      id: 'city',
      name: 'city',
      type: 'text',
      label: 'Stad',
      placeholder: userCity,
      value: userCity,
    },
    {
      id: 'postalCode',
      name: 'postal_code',
      type: 'text',
      label: 'Postnummer',
      placeholder: userPostalCode,
      value: userPostalCode,
    },
    {
      id: 'userBillingAdress',
      name: 'street',
      type: 'text',
      label: 'Faktureringsadress',
      placeholder: userBillingAdress,
      value: userBillingAdress,
    },
    {
      id: 'billingMail',
      name: 'invoice_email',
      type: 'text',
      label: 'Faktureringsmail',
      placeholder: userBillingMail,
      value: userBillingMail,
    },
    {
      id: 'reference',
      name: 'reference',
      type: 'text',
      label: 'Referens',
      placeholder: userReferences,
      value: userReferences,
    },
    {
      id: 'password',
      name: 'password',
      type: 'password',
      label: 'Lösenord',
      placeholder: userPassword,
      value: userPassword,
    },
  ];
  return (
    <UICardComponent
      width='w-full'
      padding='px-4 py-6 md:pl-12 md:pr-6 md:pt-4 md:pb-6 lg:pl-[72px] lg:pr-12 lg:pt-10 lg:pb-8'
      extraStyles='!rounded-b-lg md:!rounded-t-none'
    >
      <div className='grid grid-cols-1 md:grid-cols-2 grid-rows-4 gap-y-4 w-full'>
        {profileFormInputs && profileFormInputs.map((input, index: number) => {
          const name = input.name as keyof typeof errorMessages;
          if (input.type === 'password') {
            return (
              <ProfileFormPasswordInputComponent
                key={index}
                id={input.id}
                name={input.name}
                onChange={onChange}
                errorMessages={errorMessages[name] && errorMessages[name]}
                label={input.label}
                placeholder={(input.placeholder || input.label)}
                value={input.value ? input.value : ''}
              />
            );
          } else {
            return (
              <ProfileFormInputComponent
                key={index}
                id={input.id}
                name={input.name}
                type={input.type}
                onChange={onChange}
                errorMessages={errorMessages[name] && errorMessages[name]}
                label={input.label}
                placeholder={(input.placeholder || input.label)}
                value={input.value ? input.value : ''}
              />
            );
          }
        })}
      </div>
      <div className='flex items-center mt-16'>
        <UIButtonCommon
          extraStyles='hover:!bg-blue-dark hover:!text-white'
          buttonTitle='Spara profil'
          buttonType='button'
          onClick={onClick}
          variant='greenFilled'
        />
        {successMessage && (
          <small className='text-green-primary font-semibold text-xs ml-3'>
            Dina uppgifter är sparade!
          </small>
        )}
      </div>

    </UICardComponent>
  );
};

export default ProfileDetailsBox;
