import React, {FC, useEffect, useState} from 'react';

import useRefetchCurrentUser from '../../hooks/auth/useRefetchCurrentUser';
import useMutateUpgradeSubscription from '../../hooks/subscriptions/useMutateUpgradeSubscription';
import useMutateCreateSubscription from '../../hooks/subscriptions/useMutateCreateSubscription';
import useMutateNewsletterSubscribe from '../../hooks/newsletter/useMutateNewsletterSubscribe';

import InfoWidgetComponent from './InfoWidgetComponent';
import AccountUpgradeModal from '../UIModals/AccountUpgradeModal';
import UIFileInput from '../UICommon/UIFileInput';
import ProfileUpgradeDropdown from './ProfileUpgradeDropdown';
import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';
import UIErrorIndicator from '../UICommon/UIErrorIndicator';

type ProfileInfoBarComponentTypes = {
  userName: string;
  userOrg: string;
  userEmail: string;
  userIdNum: number;
  userId: number;
  userOrgId: number;
  setFile: Function;
  fortnoxId: number;
  avaliableUpgrades: any;
  subscriptionEnrollment: any;
  memberType: string;
  isOrganisation: boolean;
  userAvatar?: string;
  disabled?: boolean;
}

const ProfileInfoBarComponent: FC<ProfileInfoBarComponentTypes> = ({
  userName,
  userOrg,
  userEmail,
  userIdNum,
  userId,
  userOrgId,
  setFile,
  fortnoxId,
  avaliableUpgrades,
  subscriptionEnrollment,
  memberType,
  isOrganisation,
  userAvatar,
  disabled,
}) => {
  const [isModal, setIsModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>({});
  const {
    isLoading: isUpgradeLoading,
    isError: isUpgradeError,
    isSuccess: isUpgradeSuccess,
    mutate: mutateUpgrade,
  } = useMutateUpgradeSubscription();
  const {
    isLoading: isCreateLoading,
    isError: isCreateError,
    isSuccess: isCreateSuccess,
    mutate: mutateCreate,
  } = useMutateCreateSubscription();
  const {
    isLoading: isLoadingCurrentUser,
    refetch: refetchCurrentUser,
  } = useRefetchCurrentUser();
  const {
    isLoading: isNewsletterSubscribeLoading,
    isError: isNewsletterSubscribeError,
    error: newsletterSubscribeError,
    mutate: mutateSubscribeNewsletter,
  } = useMutateNewsletterSubscribe();

  const selectPlan = (obj: any) => {
    setSelectedPlan(obj);
    setIsModal(true);
  };

  const upgradePlan = () => {
    if (selectedPlan.id) {
      if (subscriptionEnrollment == null) {
        mutateCreate({
          type: isOrganisation ? 'organisation' : 'user',
          object_id: isOrganisation ? userOrgId : userId,
          subscription: selectedPlan.id,
        });
        mutateSubscribeNewsletter(userEmail);
        setIsModal(false);
      } else {
        if (subscriptionEnrollment) {
          mutateUpgrade({id: subscriptionEnrollment.id, subscription: selectedPlan.id});
          setIsModal(false);
        }
      }
    }
  };

  useEffect(() => {
    if (isUpgradeSuccess || isCreateSuccess) {
      refetchCurrentUser();
    }
  }, [isUpgradeSuccess, isCreateSuccess]);

  useEffect(() => {
    if (isNewsletterSubscribeError) {
      console.log(newsletterSubscribeError);
    }
  }, [isNewsletterSubscribeLoading, isNewsletterSubscribeError, newsletterSubscribeError]);

  if (isUpgradeLoading || isCreateLoading || isLoadingCurrentUser || isNewsletterSubscribeLoading ) {
    return (
      <UILoadingIndicatorFixed />
    );
  }

  if (isUpgradeError || isCreateError) {
    return (
      <UIErrorIndicator message='Ett fel uppstod när du försökte uppgradera ditt konto. Uppdatera sidan.' />
    );
  }

  return (
    <>
      <div className={`flex flex-col lg:flex-row lg:bg-green-light w-full lg:pl-[72px] 
      lg:pr-12 lg:pt-10 lg:pb-3 lg:rounded-t-15px lg:justify-between space-y-6 lg:space-y-0 mb-6 lg:mb-0`
      }
      >
        <div className={`w-full lg:w-1/3 xl:w-1/3 flex bg-white rounded-15px
        lg:bg-transparent px-4 py-5 lg:p-0 justify-between`}>
          <div className='flex'>
            <div className='flex flex-col items-center'>
              <UIFileInput
                id={`image-${userIdNum}`}
                label='Byt bild'
                name='avatar'
                currentAvatar={userAvatar}
                setFile={setFile}
                isPortal={false}
                disabled={disabled}
              />
            </div>
            <div className='flex flex-col text-blue-dark ml-6'>
              <h2 className='font-bold text-22px lg:text-3xl'>
                {userName}
              </h2>
              <h3 className='text-base lg:text-22px'>
                {userOrg}
              </h3>
              <span className='font-medium text-sm lg:text-base'>
                Medlemsnummer: {fortnoxId}
              </span>
            </div>
          </div>
          <div className='flex lg:hidden'>
            {!disabled && (
              <InfoWidgetComponent
                subStartDate={subscriptionEnrollment ? subscriptionEnrollment.enroll_date : ''}
                currentSubPrice={subscriptionEnrollment ?
                  subscriptionEnrollment.subscription.price + 'kr' :
                  0 + 'kr'}
              />
            )}
          </div>
        </div>
        <div className={`w-full lg:w-2/3 xl:w-2/3 flex flex-col lg:flex-row lg:space-x-16 jus lg:justify-end 
        bg-white rounded-15px lg:bg-transparent px-4 py-5 lg:p-0`}>
          <div className='flex justify-between space-x-12'>
            <div className='flex flex-col space-y-3'>
              <span className='text-gray-semi font-medium text-base'>
                Medlemstyp
              </span>
              <span className='font-bold text-22px text-blue-dark'>
                {memberType}
              </span>
            </div>
            <div className='flex flex-col space-y-3'>
              <span className='text-gray-semi font-medium text-base'>
                Status
              </span>
              <span className='font-bold text-22px text-blue-dark'>
                Aktiv
              </span>
            </div>
          </div>
          {avaliableUpgrades && avaliableUpgrades.length > 0 && (
            <div className='flex flex-col space-y-3 w-full lg:w-fit mt-6 lg:mt-0'>
              <span className='text-gray-semi font-medium text-base w-fit'>
                Uppgradera
              </span>
              <div className='w-full'>
                <ProfileUpgradeDropdown
                  currentUserQty={subscriptionEnrollment ?
                    subscriptionEnrollment.subscription.user_qty : 0}
                  avaliableUpgrades={avaliableUpgrades}
                  subscriptionEnrollment={subscriptionEnrollment}
                  selectPlan={selectPlan}
                />
              </div>
            </div>
          )}
          <div className='hidden lg:block'>
            {!disabled && (
              <InfoWidgetComponent
                subStartDate={subscriptionEnrollment ? subscriptionEnrollment.enroll_date : ''}
                currentSubPrice={subscriptionEnrollment ?
                  subscriptionEnrollment.subscription.price + 'kr' :
                  0 + 'kr'}
              />
            )}
          </div>
        </div>
      </div>
      <AccountUpgradeModal
        title='Upgradera konto'
        selectedPlan={`${selectedPlan.user_qty } personer`}
        planPrice={`${selectedPlan.price} kr/år`}
        isOpen={isModal}
        setIsOpen={setIsModal}
        upgradePlan={upgradePlan}
      />
    </>
  );
};

export default ProfileInfoBarComponent;
