import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';
import {AxiosError} from 'axios';
import {authService} from '../api/authService';
import {isValidEmail} from '../utils/isValid';

import useFetchToken from '../hooks/auth/useFetchToken';

import LoginBoxComponent from '../components/LoginPage/LoginBoxComponent';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';

import loginImage from '../assets/thumbnail.jpg';

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: authService.getUserEmailFromLocalStorage() ?? '',
    password: authService.getUserPasswordFromLocalStorage() ?? '',
    rememberMe: false,
  });
  const [formErrorMessages, setFormErrorMessages] = useState({email: '', password: ''});
  const {error, isLoading, isError, isSuccess, mutate} = useFetchToken(
      formValues.email,
      formValues.password,
      formValues.rememberMe,
  );
  const is401 = error instanceof AxiosError && error?.response?.status == 401;

  useEffect(() => {
    const userEmail = authService.getUserEmailFromLocalStorage();
    const userPass = authService.getUserPasswordFromLocalStorage();
    if (userEmail && userPass) {
      mutate();
    }
    if (isSuccess) {
      navigate('/');
    }
  }, [isSuccess]);

  const onKeyDown = (event: any) => {
    const key = event.key.toLowerCase();

    if (key === 'enter') {
      submit();
    }
  };

  const onChange = (event: any) => {
    const {name, value} = event.target;
    if (name === 'rememberMe') {
      setFormValues({...formValues, [name]: event.target.checked});
    } else {
      setFormValues({...formValues, [name]: value});
    }
  };

  const validate = () => {
    const errors = {email: '', password: ''};
    if (!formValues.email) {
      errors.email = 'Ange din e-postadress';
    } else if (!isValidEmail(formValues.email)) {
      errors.email = 'E-postadressen är ogiltig';
    }
    if (!formValues.password) {
      errors.password = 'Ange ditt lösenord';
    }
    setFormErrorMessages(errors);
    return errors.email.length == 0 && errors.password.length == 0;
  };

  const submit = () => {
    if (validate()) {
      mutate();
    }
  };

  return (
    <div className='h-screen w-full flex'>
      <div className='hidden lg:block w-1/2 relative'>
        <Link to='/' className={`bg-white/70 text-black-deep uppercase inter text-xs
         font-medium rounded-full px-4 py-3 sticky z-10 w-fit top-12 left-12`}>
          TILLBAKA TILL MIH.NU
        </Link>
        <img className='w-full h-full absolute top-0 left-0 object-cover' src={loginImage} alt="iamge" />
      </div>
      <div className='w-full lg:w-1/2 h-full flex flex-col lg:justify-center lg:items-center'>
        {isError &&
        <div className='mb-3'>
          <UIErrorIndicator
            height='h-fit'
            message={is401 ?
                  'Din e-postadress eller lösenord är fel kontrollera uppgifterna.' :
                  'Ett oväntat fel inträffade.'
            } />
        </div>
        }
        {isLoading &&
        <div className='mb-3'>
          <UILoadingIndicator height='h-fit'/>
        </div>
        }
        <LoginBoxComponent
          onChange={() => onChange}
          onKeyDown={onKeyDown}
          submit={submit}
          formErrorMessages={formErrorMessages}
        />
      </div>
    </div>
  );
};

export default LoginPage;
